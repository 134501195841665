import { getFQCN } from "@/utils/functions/fqcn";
import {
  FaBoxOpen,
  FaCar,
  FaFileInvoiceDollar,
  FaMoneyBills,
  FaRegCreditCard,
  FaRoad,
  FaTruckFast,
  FaUserGroup,
  FaUserSecret,
  FaUsers,
} from "react-icons/fa6";
import { HiCurrencyDollar } from "react-icons/hi";
import { LuCalendarClock, LuLayoutDashboard } from "react-icons/lu";
import { MdInventory2, MdOutlineConnectWithoutContact, MdOutlineWork, MdRoomPreferences } from "react-icons/md";
import { RiContactsBook3Fill, RiOrganizationChart } from "react-icons/ri";
import { GoTasklist } from "react-icons/go";
import {
  HiOutlineBanknotes,
  HiOutlineClipboardDocumentList,
} from "react-icons/hi2";
import { GrMoney, GrNotes } from "react-icons/gr";
import { TbArrowsDiff } from "react-icons/tb";
import { PiBookOpenTextThin } from "react-icons/pi";
import { AiOutlineComment } from "react-icons/ai";

const fqcn_bui = {
  Bundle: "sidebarMenuBundle",
  Unit: "menu",
  Interface: "Listing",
};

export const SidebarMenus = [
  {
    title: "Dashboard",
    icon: (
      <LuLayoutDashboard
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "dashboard-icon")}
      />
    ),
    link: "/dashboard",
  },
  {
    title: "Products",
    icon: (
      <FaBoxOpen
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "products-icon")}
      />
    ),
    subMenus: [
      {
        title: "Products",
        link: "/products",
        icon: (
          <FaBoxOpen
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "products-icon")}
          />
        ),
      },
      {
        title: "Inventories",
        link: "/inventories",
        icon: (
          <MdInventory2
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "inventories-icon")}
          />
        ),
      },
      {
        title: "Suppliers",
        link: "/suppliers",
        icon: (
          <RiOrganizationChart
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "suppliers-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/fonctionnalites-de-produit/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Rents",
    icon: (
      <MdRoomPreferences
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "rents-icon")}
      />
    ),
    subMenus: [
      {
        title: "Rents",
        link: "/rents",
        icon: (
          <MdRoomPreferences
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "rents-icon")}
          />
        ),
      },
      {
        title: "Employees",
        link: "/employees",
        icon: (
          <FaUserGroup
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "employees-icon")}
          />
        ),
      },
      {
        title: "Clients",
        link: "/clients",
        icon: (
          <FaUserSecret
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "clients-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/fonctionnalites-de-reservations/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Sales",
    icon: (
      <HiCurrencyDollar
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "sales-icon")}
      />
    ),
    subMenus: [
      {
        title: "Invoices",
        link: "/invoices",
        icon: (
          <FaFileInvoiceDollar
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "invoices-icon")}
          />
        ),
      },
      {
        title: "Clients",
        link: "/clients",
        icon: (
          <FaUserSecret
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "clients-icon")}
          />
        ),
      },
      {
        title: "Shipments",
        link: "/shipments",
        icon: (
          <FaRoad
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "shipments-icon")}
          />
        ),
      },
      {
        title: "Shippers",
        link: "/shippers",
        icon: (
          <FaTruckFast
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "shippers-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/fonctionnalites-de-ventes/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Bills",
    icon: (
      <FaMoneyBills
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "bills-icon")}
      />
    ),
    subMenus: [
      {
        title: "Bills",
        link: "/bills",
        icon: (
          <FaMoneyBills
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "bills-icon")}
          />
        ),
      },
      {
        title: "Suppliers",
        link: "/suppliers",
        icon: (
          <RiOrganizationChart
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "suppliers-icon")}
          />
        ),
      },
      {
        title: "Shipments",
        link: "/shipments",
        icon: (
          <FaRoad
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "shipments-icon")}
          />
        ),
      },
      {
        title: "Shippers",
        link: "/shippers",
        icon: (
          <FaTruckFast
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "shippers-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/fonctionnalites-dachats/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Projects",
    icon: (
      <MdOutlineWork
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "projects-icon")}
      />
    ),
    subMenus: [
      {
        title: "Project Manager",
        link: "/project",
        icon: (
          <MdOutlineWork
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "project-manager-icon")}
          />
        ),
      },
      {
        title: "Task Manager",
        link: "/project-tasks",
        icon: (
          <GoTasklist
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "task-manager-icon")}
          />
        ),
      },
      {
        title: "Timesheets",
        link: "/timesheets",
        icon: (
          <LuCalendarClock
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "timesheets-icon")}
          />
        ),
      },
      {
        title: "Clients",
        link: "/clients",
        icon: (
          <FaUserSecret
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "clients-icon")}
          />
        ),
      },
      {
        title: "Employees",
        link: "/employees",
        icon: (
          <FaUserGroup
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "employees-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/details-fonctionnalites-de-projet/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Salary",
    icon: (
      <HiOutlineBanknotes
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "salary-icon")}
      />
    ),
    subMenus: [
      {
        title: "Payrolls",
        link: "/payrolls",
        icon: (
          <MdOutlineWork
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "payrolls-icon")}
          />
        ),
      },
      {
        title: "Payments",
        link: "/payments",
        icon: (
          <FaRegCreditCard
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "payments-icon")}
          />
        ),
      },
      {
        title: "Allowances",
        link: "/allowances",
        icon: (
          <FaCar
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "allowances-icon")}
          />
        ),
      },
      {
        title: "Bonuses",
        link: "/bonuses",
        icon: (
          <GrMoney
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "bonuses-icon")}
          />
        ),
      },
      {
        title: "Employees",
        link: "/employees",
        icon: (
          <FaUserGroup
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "employees-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/details-fonctionnalites-de-salaire/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Relations",
    icon: (
      <RiContactsBook3Fill
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "relations-icon")}
      />
    ),
    subMenus: [
      {
        title: "Directory",
        link: "/coordinates",
        icon: (
          <RiContactsBook3Fill
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "coordinates-icon")}
          />
        ),
      },
      {
        title: "Clients",
        link: "/clients",
        icon: (
          <FaUserSecret
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "clients-icon")}
          />
        ),
      },
      {
        title: "Employees",
        link: "/employees",
        icon: (
          <FaUserGroup
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "employees-icon")}
          />
        ),
      },
      {
        title: "Partners",
        link: "/partners",
        icon: (
          <TbArrowsDiff
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "partners-icon")}
          />
        ),
      },
      {
        title: "Competitors",
        link: "/person-competitors",
        icon: (
          <MdOutlineWork
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "person-competitors-icon")}
          />
        ),
      },
      {
        title: "Suppliers",
        link: "/suppliers",
        icon: (
          <RiOrganizationChart
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "suppliers-icon")}
          />
        ),
      },
      {
        title: "Documentation",
        link: "https://phpreaction.com/fonctionnalites-de-phpreaction/fonctionnalites-de-recrutement/#CommentFaire",
        icon: (
          <PiBookOpenTextThin
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "documentation-icon")}
          />
        ),
      },
    ],
  },
  {
    title: "Notes",
    icon: (
      <GrNotes
        className="h-5 w-5 mx-auto"
        id={getFQCN(fqcn_bui, "menu", "notes-icon")}
      />
    ),
    subMenus: [
      {
        title: "Notes",
        link: "/notes",
        icon: (
          <GrNotes
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "notes-icon")}
          />
        ),
      },
      {
        title: "Comments",
        link: "/note-comments",
        icon: (
          <AiOutlineComment
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "note-comments-icon")}
          />
        ),
      },
      {
        title: "Participants",
        link: "/note-participants",
        icon: (
          <FaUsers
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "note-participants-icon")}
          />
        ),
      },
      {
        title: "Status",
        link: "/note-statuses",
        icon: (
          <HiOutlineClipboardDocumentList
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "note-statuses-icon")}
          />
        ),
      },
      {
        title: "Connections",
        link: "/note-connections",
        icon: (
          <MdOutlineConnectWithoutContact 
            className="h-5 w-5 mx-auto"
            id={getFQCN(fqcn_bui, "menu", "note-connections-icon")}
          />
        ),
      },
    ],
  },
];
