"use client";

import { GetTenantCore } from "./getTenantBackend";

export default function GetTenant() {
  var urlSplit: Array<string> | undefined;
  if (typeof window !== "undefined")
    urlSplit = window.location.hostname.split(".");

  return GetTenantCore(urlSplit);
}
